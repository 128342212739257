import React from "react";
import styled from "styled-components";
import QuoteElement from "../../../components/quote-element/quote-element";
import { Paragraph } from "../../../components/typography";
import WorkVideoElement from "../work-video-element";
import { PageElement } from "../../../graphql/page-common";
import { getElementImage, getElementText, getElementVideo } from "../../../utilities/pimcore-data-transformer";
import { PimcoreImage } from "../../../components/pimcore-image";

interface WorkingSectionProps {
  intro: Array<PageElement>;
}

const WorkingSection: React.FC<WorkingSectionProps> = ({ intro }) => {
  return (
    <Wrapper>
      <WorkVideoElement
        video={getElementVideo(intro[0])}
        image={getElementImage(intro[0])}
        content={getElementText(intro[1])}
      >
        <MediaCell1>
          <Paragraph as="div" dangerouslySetInnerHTML={{ __html: getElementText(intro[2]) }} />
        </MediaCell1>
        <MediaCell2>
          <QuoteElement
            message="By stimulating a knowledge-sharing culture, I ensure knowledge is shared with the right people in the right place at the right time."
            name="Jinny"
            position="Head of Knowledge Management"
            modifier="left-4 bottom-4 tablet:bottom-80 tablet:left-[25rem]"
            left={true}
          />
          <PimcoreImage image={getElementImage(intro[3])} modifier="w-full rounded-lg" withAspectRatio />
        </MediaCell2>
        <MediaCell3>
          <Paragraph as="div" dangerouslySetInnerHTML={{ __html: getElementText(intro[4]) }} />
        </MediaCell3>
        <MediaCell4>
          <QuoteElement
            message="We enable our mission, by bringing locals into one place, increasing efficiency and providing digital skills training."
            name="Yali"
            position="Head of Country Success"
            modifier="left-4 bottom-4 tablet:left-auto tablet:bottom-72 tablet:right-96"
          />
          <PimcoreImage image={getElementImage(intro[5])} modifier="w-full rounded-lg" withAspectRatio />
        </MediaCell4>
      </WorkVideoElement>
    </Wrapper>
  );
};

export default WorkingSection;

const Wrapper = styled.div.attrs({
  className: "-mt-64 tablet:-mt-72 relative z-20 scrollspy",
})``;
const MediaCell1 = styled.div.attrs({
  className:
    "tablet:col-start-2 tablet:col-span-4 tablet:row-start-2 tablet:row-span-1 -mt-8 tablet:mt-24 tablet:pr-16 mb-8 tablet:mb-0",
})``;
const MediaCell2 = styled.div.attrs({
  className: "tablet:col-start-7 tablet:col-span-5 tablet:row-start-2 tablet:row-span-4 mb-8 tablet:mb-0 relative",
})``;
const MediaCell3 = styled.div.attrs({
  className: "tablet:col-start-8 tablet:col-span-4 tablet:row-start-7 tablet:row-span-1 tablet:pr-16 mb-8 tablet:mb-0",
})``;
const MediaCell4 = styled.div.attrs({
  className: "tablet:col-start-2 tablet:col-span-5 tablet:row-start-4 tablet:row-span-4 mb-20 tablet:mb-0 relative",
})``;
