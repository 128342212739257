import React from "react";
import styled from "styled-components";
import ExampleHeaderSection from "../../../components/work/example-header-section";
import SimpleSection from "../../../components/work/simple-section";
import { PageElement } from "../../../graphql/page-common";
import { getElementImage, getElementText, getElementVideo } from "../../../utilities/pimcore-data-transformer";

interface ExamplesSectionProps {
  headline: string;
  content: Array<PageElement>;
}

const ExamplesSection: React.FC<ExamplesSectionProps> = ({ headline, content }) => {
  return (
    <SectionWrapper>
      <ExampleHeaderSection
        headline={headline}
        subHeadline={getElementText(content[0])}
        primaryImage={getElementImage(content[1])}
        content={getElementText(content[2])}
        secondaryImage={getElementImage(content[3])}
        marginTop="tablet:mt-4"
        prParagraph="4"
      />
      <SimpleSection
        headline={getElementText(content[4])}
        video={getElementVideo(content[5])}
        image={getElementImage(content[5])}
        content={getElementText(content[6])}
        marginTop="tablet:mt-4"
        padding="tablet:pr-10"
      />
      <SimpleSection
        headline={getElementText(content[7])}
        image={getElementImage(content[8])}
        content={getElementText(content[9])}
        left
        marginTop="tablet:mt-14"
        imgFull
        padding="tablet:pr-4"
      />
    </SectionWrapper>
  );
};

export default ExamplesSection;
const SectionWrapper = styled.div.attrs({ className: "scrollspy" })``;
